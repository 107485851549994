.modal {

    .defaultModal {

        .defaultHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            margin-bottom: 30px;

            h1 {
                color: var(--text);
                font-weight: 700;
                font-size: 28px;
            }

            .close {
                width: 28px;
                height: 28px;

                color: var(--textSecond);

                transition: transform .15s ease;

                &:hover {
                    transform: scale(1.05);
                }
            }
        }
        
    }

    .acceptRules {
        display: flex;
        align-items: center;
        gap: 15px;

        font-size: 12px;
        color: var(--textSecond);
    }

    .modalBtnRow {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 15px;

        .mainBtn {
            height: 50px;
            padding: 0 20px;
        }
    }

    .instStep {
        display: grid;
        grid-template-columns: 40px 1fr;
        gap: 15px;

        margin-bottom: 25px;

        

        .text {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .image {
                width: 250px;

                &.long {
                    width: 100%;
                }
            }
        }

        .stepCircle {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;

            border-radius: 50%;

            font-size: 28px;
            font-weight: 700;

            background: var(--accent);

            &.selctor {
                cursor: pointer;
                background-color: var(--block);
            }

            &.active {
                .indicator {
                    opacity: 1;
                }
            }

            .indicator {
                width: 30px;
                height: 30px;
                border-radius: 50%;

                background-color: var(--accent);

                opacity: 0;

                transition: opacity .3s ease;
            }
        }

        h4 {
            font-size: 16px;
            font-weight: 700;

            padding-bottom: 5px;
        }

        span {
            color: #475774;

            font-size: 12px;
            line-height: 170%;

            padding-bottom: 5px;
        }

        .qr {
            width: 200px;
            height: 200px;
            
            border-radius: 20px;
            background-color: var(--block);

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                width: 170px;
                height: 170px;
            }
        }

        a {
            margin-left: 5px;
            text-decoration: underline;
            color: var(--accent);
        }

        .copyText {
            padding: 3px 5px;

            cursor: pointer;

            margin-bottom: 10px;
            border-radius: 10px;
            font-size: 12px;

            background-color: var(--block)
        }

        .intructionImg {
            max-width: 250px;
            width: 100%;

            border-radius: 20px;

            margin-bottom: 10px;
        }

        .circle {
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background: #475774;

            opacity: 0.5;

            margin-bottom: 10px;

            &.one {
                width: 7px;
                height: 7px;
            }

            &.two {
                width: 5px;
                height: 5px;
            }
        }
    }

    .single {
        height: 50px;
        margin-top: 85px;
    }

    .rule {
        height: 45px;
    }

    .inLine {
        display: grid;
        grid-gap: 10px;

        grid-template-columns: 1fr auto;

        .field {
            input {
                height: 50px;
            }
        }

        .mainBtn {
            padding: 0 15px;
        }
    }
}