
.loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--back);

    position: absolute;
    left: 0;
    top: 0;

    svg {
        color: var(--text);
        width: 90px;
        height: 90px;

        animation: rotate 2s linear infinite;
    }
}