.modal .defaultModal .defaultHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.modal .defaultModal .defaultHeader h1 {
  color: var(--text);
  font-weight: 700;
  font-size: 28px;
}
.modal .defaultModal .defaultHeader .close {
  width: 28px;
  height: 28px;
  color: var(--textSecond);
  transition: transform 0.15s ease;
}
.modal .defaultModal .defaultHeader .close:hover {
  transform: scale(1.05);
}
.modal .acceptRules {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 12px;
  color: var(--textSecond);
}
.modal .modalBtnRow {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 15px;
}
.modal .modalBtnRow .mainBtn {
  height: 50px;
  padding: 0 20px;
}
.modal .instStep {
  display: grid;
  grid-template-columns: 40px 1fr;
  gap: 15px;
  margin-bottom: 25px;
}
.modal .instStep .text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.modal .instStep .text .image {
  width: 250px;
}
.modal .instStep .text .image.long {
  width: 100%;
}
.modal .instStep .stepCircle {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 28px;
  font-weight: 700;
  background: var(--accent);
}
.modal .instStep .stepCircle.selctor {
  cursor: pointer;
  background-color: var(--block);
}
.modal .instStep .stepCircle.active .indicator {
  opacity: 1;
}
.modal .instStep .stepCircle .indicator {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: var(--accent);
  opacity: 0;
  transition: opacity 0.3s ease;
}
.modal .instStep h4 {
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 5px;
}
.modal .instStep span {
  color: #475774;
  font-size: 12px;
  line-height: 170%;
  padding-bottom: 5px;
}
.modal .instStep .qr {
  width: 200px;
  height: 200px;
  border-radius: 20px;
  background-color: var(--block);
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal .instStep .qr svg {
  width: 170px;
  height: 170px;
}
.modal .instStep a {
  margin-left: 5px;
  text-decoration: underline;
  color: var(--accent);
}
.modal .instStep .copyText {
  padding: 3px 5px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 12px;
  background-color: var(--block);
}
.modal .instStep .intructionImg {
  max-width: 250px;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
}
.modal .instStep .circle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #475774;
  opacity: 0.5;
  margin-bottom: 10px;
}
.modal .instStep .circle.one {
  width: 7px;
  height: 7px;
}
.modal .instStep .circle.two {
  width: 5px;
  height: 5px;
}
.modal .single {
  height: 50px;
  margin-top: 85px;
}
.modal .rule {
  height: 45px;
}
.modal .inLine {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr auto;
}
.modal .inLine .field input {
  height: 50px;
}
.modal .inLine .mainBtn {
  padding: 0 15px;
}/*# sourceMappingURL=auth.css.map */