.modalWrapper {
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;

    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;

    &.unscroll {
        overflow-y: hidden;
    }

    @media (min-height: 700px) {
        align-items: center;
        justify-content: center;
    }


    @media (max-width: 500px) {
        .remember {
            span {
                font-size: 11px !important;
            }
        }

        .switch {
            width: 100%;
            max-width: 100% !important;
            height: 45px !important;

            display: grid;
            grid-template-columns: 1fr 1fr 1fr;

            .item {
                max-width: 100%;
                width: 100% !important;

                font-size: 14px !important;

                &.active {
                    &:after {
                        opacity: 1;
                    }
                }

                &.last {
                    &:after {
                        width: calc(100% - 6px);
                    }
                }
            }

            .mainBtn {
                display: none;
            }
        }
   

        .inputs {
            .field {
                .input {
                    input {
                        height: 45px !important;
                        font-size: 1rem !important;
                    }
                }
            }
        }


    }

    &.disappear {
        .modal {
            animation: fadeOut 300ms ease forwards;
        }
    }

    .modal {
        position: relative;
        width: 600px;
        max-width: 100%;
        border-radius: 25px;
        min-height: 400px;
        position: absolute;

        background-color: var(--back);
        border: 2px solid var(--block);

        padding: 20px;

        opacity: 0;

        @media (max-width: 450px) {
            &.mtn {
                border-radius: 20px !important;
            }
            
        }

        .close {
            cursor: pointer;
        }

        animation: fadeIn 300ms ease forwards;

        @media (max-width: 900px) {
            // height: 100% !important;

            .left {
                display: none;
            }

            .image {
                display: none;
            }
        }

        @media (max-width: 600px) {

            .signIn,
            .signUp {
                .form {
                    padding: 30px 10px;
                }
            }
        }

    }

    .top {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h1 {
            font-size: 1.5rem;
            font-weight: 700;
        }

        &:after {
            content: "";
            background: #475774;
            height: 1px;
            width: 100%;
            position: absolute;
            bottom: -20px;

            opacity: 0.4;
        }

        svg {
            path {
                fill: #475774;
                transition: fill 300ms ease;
            }

            &:hover path {
                fill: #FFFFFF;
            }
        }
    }


    .l {
        font-size: 1rem;
        padding: 0 20px;
        margin-bottom: 10px;
        font-weight: 300;
    }

    @media (max-height: 700px) {
        .modal{
            
            min-height: 100vh;
            border-radius: 0;


            .form{
                padding: 15px!important;
                padding-bottom: 20px!important;
            }
        }
    }
}

$char-w: 1ch;
$gap: 0.5 * $char-w;
$n-char: 6;
$in-w: $n-char * ($char-w + $gap);

.react-code-input {
    display: flex !important;
    justify-content: center;
    align-items: center;

    input {
        text-transform: uppercase;

        padding: 0;
        width: $in-w;

        color: var(--text);
        background-color: var(--back);
        height: 50px;
        border-radius: 25px;
        border: 2px solid var(--block);
        font-size: 1.1rem;
        transition: border-color 300ms ease;

        transition: color 0.3s ease-out, opacity 0.3s ease-out;

        &::placeholder {
            color: var(--textSecond);
            opacity: 1;
            /* Firefox */
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--text);
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px var(--back);

            transition: border-color .3s ease;
        }

        &:focus {
            border-color: var(--accent) !important;
        }
    }
}