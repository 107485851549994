.modalWrapper {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 100vh;
}
.modalWrapper.unscroll {
  overflow-y: hidden;
}
@media (min-height: 700px) {
  .modalWrapper {
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 500px) {
  .modalWrapper .remember span {
    font-size: 11px !important;
  }
  .modalWrapper .switch {
    width: 100%;
    max-width: 100% !important;
    height: 45px !important;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .modalWrapper .switch .item {
    max-width: 100%;
    width: 100% !important;
    font-size: 14px !important;
  }
  .modalWrapper .switch .item.active:after {
    opacity: 1;
  }
  .modalWrapper .switch .item.last:after {
    width: calc(100% - 6px);
  }
  .modalWrapper .switch .mainBtn {
    display: none;
  }
  .modalWrapper .inputs .field .input input {
    height: 45px !important;
    font-size: 1rem !important;
  }
}
.modalWrapper.disappear .modal {
  animation: fadeOut 300ms ease forwards;
}
.modalWrapper .modal {
  position: relative;
  width: 600px;
  max-width: 100%;
  border-radius: 25px;
  min-height: 400px;
  position: absolute;
  background-color: var(--back);
  border: 2px solid var(--block);
  padding: 20px;
  opacity: 0;
  animation: fadeIn 300ms ease forwards;
}
@media (max-width: 450px) {
  .modalWrapper .modal.mtn {
    border-radius: 20px !important;
  }
}
.modalWrapper .modal .close {
  cursor: pointer;
}
@media (max-width: 900px) {
  .modalWrapper .modal .left {
    display: none;
  }
  .modalWrapper .modal .image {
    display: none;
  }
}
@media (max-width: 600px) {
  .modalWrapper .modal .signIn .form,
  .modalWrapper .modal .signUp .form {
    padding: 30px 10px;
  }
}
.modalWrapper .top {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modalWrapper .top h1 {
  font-size: 1.5rem;
  font-weight: 700;
}
.modalWrapper .top:after {
  content: "";
  background: #475774;
  height: 1px;
  width: 100%;
  position: absolute;
  bottom: -20px;
  opacity: 0.4;
}
.modalWrapper .top svg path {
  fill: #475774;
  transition: fill 300ms ease;
}
.modalWrapper .top svg:hover path {
  fill: #FFFFFF;
}
.modalWrapper .l {
  font-size: 1rem;
  padding: 0 20px;
  margin-bottom: 10px;
  font-weight: 300;
}
@media (max-height: 700px) {
  .modalWrapper .modal {
    min-height: 100vh;
    border-radius: 0;
  }
  .modalWrapper .modal .form {
    padding: 15px !important;
    padding-bottom: 20px !important;
  }
}

.react-code-input {
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.react-code-input input {
  text-transform: uppercase;
  padding: 0;
  width: 9ch;
  color: var(--text);
  background-color: var(--back);
  height: 50px;
  border-radius: 25px;
  border: 2px solid var(--block);
  font-size: 1.1rem;
  transition: border-color 300ms ease;
  transition: color 0.3s ease-out, opacity 0.3s ease-out;
}
.react-code-input input::-moz-placeholder {
  color: var(--textSecond);
  opacity: 1;
  /* Firefox */
}
.react-code-input input::placeholder {
  color: var(--textSecond);
  opacity: 1;
  /* Firefox */
}
.react-code-input input:-webkit-autofill, .react-code-input input:-webkit-autofill:hover, .react-code-input input:-webkit-autofill:focus, .react-code-input input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--text);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px var(--back);
  -webkit-transition: border-color 0.3s ease;
  transition: border-color 0.3s ease;
}
.react-code-input input:focus {
  border-color: var(--accent) !important;
}/*# sourceMappingURL=modals.css.map */