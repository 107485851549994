@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  box-sizing: border-box;
  font: inherit;
  vertical-align: baseline;
}

/* make sure to set some focus styles for accessibility */
:focus {
  outline: 0;
}

a {
  text-decoration: none;
  color: #fff;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  color: #fff;
  background: #ffffff;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background: rgba(0, 0, 0, 0);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
  -ms-text-size-adjust: 100%; /* 2 */
  scrollbar-width: none;
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
a:active,
a:hover {
  outline: 0;
}

/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0; /* 1 */
  -ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0; /* 1 */
  padding: 0;
  white-space: normal; /* 2 */
  *margin-left: -7px; /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%; /* 1 */
  margin: 0; /* 2 */
  vertical-align: baseline; /* 3 */
  *vertical-align: middle; /* 3 */
}

button {
  padding: 0;
  margin: 0;
  border: 0;
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input,
textarea {
  line-height: normal;
  -webkit-appearance: none;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible; /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
  *height: 13px; /* 3 */
  *width: 13px; /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield; /* 1 */ /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto; /* 1 */
  vertical-align: top; /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  box-sizing: border-box;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.noCopy {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

:root {
  --back: #0C111C;
  --text: #FFFFFF;
  --textSecond: #475774;
  --block: #192232;
  --accent: #0090FF;
  --backOpacity: #0C111CCC;
}

body {
  background-color: var(--back);
  color: var(--text);
  font-family: "Inter", sans-serif;
}

.safe-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-thumb {
  background: #3F4E69;
  border-radius: 2px;
  -webkit-transition: background-color 0.3s ease-out;
  transition: background-color 0.3s ease-out;
}

::-webkit-scrollbar-track {
  background: #0C111C;
}

input::-moz-placeholder {
  color: var(--textSecond);
  opacity: 1;
}

input::placeholder {
  color: var(--textSecond);
  opacity: 1;
}

input {
  font-family: "Inter", sans-serif;
  outline: 0;
}

img {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.content {
  max-width: 100%;
  width: 720px;
  margin: auto;
  padding-bottom: 50px;
}
@media (max-width: 720px) {
  .content {
    padding: 0 10px 50px 10px;
  }
}

.container {
  position: relative;
  max-width: 100vw;
  min-height: 100vh;
  margin: auto;
}
@media (max-width: 700px) {
  .container {
    padding-top: 0;
  }
}
@keyframes containerBluringIn {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(10px);
  }
}
@keyframes containerBluringOut {
  0% {
    filter: blur(10px);
  }
  100% {
    filter: blur(0px);
  }
}
.container.blurIn {
  filter: blur(10px);
}
.container.blurOut {
  filter: blur(0px);
}

.mainBtn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: var(--block);
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 16px;
  font-weight: 700;
  color: var(--text);
  border: 2px solid var(--block);
  transition: border-color 0.15s ease, transform 0.15s ease, background-color 0.15s ease;
}
.mainBtn.regular {
  font-weight: 400;
}
.mainBtn.tg {
  background-color: #43B4CD;
  border-color: #43B4CD;
}
.mainBtn.tg:hover {
  border-color: #4ECBE7;
}
.mainBtn.blue {
  border-color: var(--accent);
  background-color: var(--accent);
}
.mainBtn.blue.half {
  opacity: 0.7;
}
.mainBtn.blue.half:hover {
  transform: scale(1);
}
.mainBtn.blue:hover {
  border-color: var(--accent);
  transform: scale(1.02);
}
.mainBtn:hover {
  border-color: var(--textSecond);
}
.mainBtn .btnText {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mainBtn .absIcon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  color: #475774;
}
.mainBtn .mainImg {
  margin-right: 10px;
  width: 22px;
  color: var(--accent);
}

.field {
  width: 100%;
}
@media (max-width: 500px) {
  .field .label {
    font-size: 16px !important;
  }
  .field input, .field textarea {
    height: 55px !important;
    font-size: 22px !important;
  }
}
.field .quick {
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
  grid-gap: 15px;
  padding-top: 5px;
}
@media (max-width: 600px) {
  .field .quick .mainBtn {
    height: 45px !important;
    font-size: 18px !important;
  }
}
@media (max-width: 500px) {
  .field .quick {
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    padding: 0 10px !important;
  }
}
.field .quick .mainBtn {
  height: 50px;
  font-size: 22px;
}
.field .label {
  font-size: 18px;
  padding: 0 20px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--textSecond);
}
.field input, .field textarea {
  color: var(--text);
  background-color: var(--back);
  height: 60px;
  width: 100%;
  border-radius: 20px;
  border: 3px solid var(--block);
  padding: 0 20px;
  font-size: 1.1rem;
  transition: border-color 150ms ease;
  font-family: "Inter" !important;
  box-shadow: 0px 5px 6px 0px rgba(17, 20, 27, 0.12);
}
.field input:hover, .field input:focus, .field textarea:hover, .field textarea:focus {
  border-color: var(--accent) !important;
}
.field input::-moz-placeholder, .field textarea::-moz-placeholder {
  opacity: 1;
  /* Firefox */
}
.field input::placeholder, .field textarea::placeholder {
  opacity: 1;
  /* Firefox */
}
.field input:-webkit-autofill, .field input:-webkit-autofill:hover, .field input:-webkit-autofill:focus, .field input:-webkit-autofill:active, .field textarea:-webkit-autofill, .field textarea:-webkit-autofill:hover, .field textarea:-webkit-autofill:focus, .field textarea:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: var(--accent);
  -webkit-transition: background-color 5000s ease-in-out 0s;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px var(--accent);
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
}
.field .input {
  position: relative;
}
.field .input .absPart {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 28px;
  color: var(--accent);
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}
.field .input .absPart svg {
  color: var(--accent);
  width: 27px;
  height: 27px;
}
.field .input .absPart img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.field .input:hover input, .field .input:focus input {
  border-color: var(--accent) !important;
}
.field .input .action {
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translate(-50%, -50%);
  height: 25px;
  width: 25px;
}
.field .input .action svg {
  height: 100%;
  width: 100%;
  opacity: 1;
  color: var(--textSecond);
}

.dropdown {
  cursor: default;
  position: absolute;
  width: 215px;
  padding: 15px 10px 15px 10px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  top: calc(100% + 25px);
  right: 0;
  transform: translate(0, 0);
  border-radius: 25px;
  animation: selectAppear 300ms ease both;
  background-color: var(--block);
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  z-index: 99;
  box-shadow: 0px 1px 10px 0px rgba(17, 20, 27, 0.5);
}
.dropdown.centered {
  transform: translate(50%) !important;
  right: 50%;
  animation: centerAppear 300ms ease both;
}
@keyframes centerAppear {
  0% {
    transform: translate(-30px, -50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0px, -50%);
    opacity: 1;
  }
}
.dropdown.disappear {
  animation: fadeOut;
  animation-duration: 200ms;
  animation-timing-function: ease-out;
}
.dropdown.grid {
  padding: 5px 5px;
}
.dropdown .displayBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 25px;
}
.dropdown .displayBlock::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 1px;
  background: #263144;
}
.dropdown .displayBlock h6 {
  font-size: 16px;
  font-weight: 700;
}
.dropdown .list {
  overflow-y: auto;
  overflow-x: hidden;
  display: grid;
  grid-template-rows: repeat(auto-fit, 45px);
  grid-gap: 10px;
}
.dropdown .list h5 {
  font-size: 14px;
  font-weight: 700;
  padding: 0 15px 15px 15px;
}
.dropdown .list .listBlock {
  position: relative;
}
.dropdown .list .listBlock:last-child {
  padding: 0;
  margin: 0;
}
.dropdown .list .loadBox {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 199;
  -webkit-backdrop-filter: blur(7px);
          backdrop-filter: blur(7px);
  border-radius: 30px;
}
.dropdown .list::-webkit-scrollbar-track {
  margin: 25px 0;
}
.dropdown::after {
  content: "";
  position: absolute;
  top: -8px;
  left: calc(50% - 16px);
  transform: translateX(-50%);
  width: 25px;
  height: 25px;
  background-color: var(--block);
  border-radius: 5px;
  z-index: -1;
  transform: rotate(45deg) translateZ(-1px);
}
.dropdown .head {
  position: relative;
  font-size: 18px;
  font-weight: 300;
  padding: 0 15px 15px 15px;
}
.dropdown .head:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  height: 1px;
  background-color: #273140;
}

.mainbase .title {
  display: flex;
  align-items: flex-end;
  margin-top: 50px;
  margin-bottom: 30px;
}
@media (max-width: 660px) {
  .mainbase .title.po {
    align-items: center !important;
  }
  .mainbase .title.po h2 {
    font-size: 26px !important;
  }
}
@media (max-width: 500px) {
  .mainbase .title {
    margin-top: 30px;
  }
  .mainbase .title h2 {
    font-size: 38px !important;
  }
  .mainbase .title h5 {
    font-size: 18px !important;
  }
  .mainbase .title .count {
    width: 45px !important;
    height: 45px !important;
    font-size: 20px !important;
    margin-right: 10px !important;
  }
  .mainbase .title svg {
    width: 35px !important;
    height: 35px !important;
  }
}
.mainbase .title .count {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  font-weight: 700;
  color: var(--accent);
  background-color: var(--block);
  border-radius: 50%;
  margin-right: 15px;
}
.mainbase .title .titleImg {
  width: 40px;
  height: 40px;
  margin-right: 15px;
  color: var(--accent);
}
.mainbase .title h2 {
  font-weight: 800;
  font-size: 48px;
  line-height: 90%;
}
.mainbase .title h5 {
  font-size: 22px;
  font-weight: 500;
  color: var(--textSecond);
  margin-left: 10px;
}

.mainStatus {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 500px) {
  .mainStatus .statusHead {
    width: 70px !important;
    height: 70px !important;
  }
  .mainStatus .statusHead svg {
    width: 55px !important;
    height: 55px !important;
  }
  .mainStatus h3 {
    font-size: 24px !important;
  }
  .mainStatus h4 {
    max-width: 350px !important;
    font-size: 14px !important;
    line-height: 135% !important;
  }
}
.mainStatus .statusHead {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--block);
  margin-bottom: 20px;
}
.mainStatus .statusHead svg {
  color: var(--accent);
  width: 70px;
  height: 70px;
}
.mainStatus .statusHead svg.load {
  animation: rotate 2s linear infinite;
}
.mainStatus h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 10px;
  text-align: center;
}
.mainStatus h4 {
  width: 100%;
  max-width: 550px;
  text-align: center;
  line-height: 170%;
  color: var(--textSecond);
}

.custom-toast {
  background-color: var(--block);
}

.blockLoader {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
}
.blockLoader svg {
  color: var(--accent);
  width: 50px;
  height: 50px;
  animation: rotate 2s linear infinite;
}

.switcher {
  cursor: pointer;
  width: 55px;
  height: 23px;
  border-radius: 23px;
  background-color: var(--block);
  border: 2px solid var(--block);
  position: relative;
}
.switcher.on .inside {
  box-shadow: 0px 5px 6px 0px rgba(17, 20, 27, 0.12);
  opacity: 1;
  left: calc(100% + 2px);
  transform: translateY(-50%) translateX(-100%);
}
.switcher .inside {
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background: var(--accent);
  position: absolute;
  top: 50%;
  left: -2px;
  transform: translateY(-50%);
  transition: opacity 0.3s ease, box-shadow 0.5s ease, left 0.3s ease, transform 0.3s ease;
  opacity: 0.5;
}/*# sourceMappingURL=global.css.map */