@import 'reset';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

@mixin noCopy {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noCopy {
    @include noCopy;
}

:root {
    --back: #0C111C;
    --text: #FFFFFF;
    --textSecond: #475774;
    --block: #192232;
    --accent: #0090FF;
    --backOpacity: #0C111CCC;
}

body {
    background-color: var(--back);
    color: var(--text);
    font-family: 'Inter', sans-serif;
}

.safe-bottom {
    padding-bottom: env(safe-area-inset-bottom);
}

::-webkit-scrollbar {
    width: 2px;
}

::-webkit-scrollbar-thumb {
    background: #3F4E69;
    border-radius: 2px;
    -webkit-transition: background-color .3s ease-out;
    transition: background-color .3s ease-out;
}


::-webkit-scrollbar-track {
    background: #0C111C;
}

input::placeholder {
    color: var(--textSecond);
    opacity: 1;
}

input {
    font-family: 'Inter', sans-serif;
    outline: 0;
}

img {
    @include noCopy;
}

.content {
    max-width: 100%;
    width: 720px;
    margin: auto;
    padding-bottom: 50px;

    @media (max-width: 720px) {
        padding: 0 10px 50px 10px;
    }
}

.container {
    position: relative;
    max-width: 100vw;
    min-height: 100vh;
    margin: auto;

    @media (max-width: 700px) {
        padding-top: 0;
    }

    @keyframes containerBluringIn {
        0% {
            filter: blur(0px);
        }

        100% {
            filter: blur(10px);
        }
    }

    @keyframes containerBluringOut {
        0% {
            filter: blur(10px);
        }

        100% {
            filter: blur(0px);
        }
    }

    &.blurIn {
        filter: blur(10px);
    }

    &.blurOut {
        filter: blur(0px);
    }
}

.mainBtn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 15px;

    background-color: var(--block);

    cursor: pointer;

    @include noCopy();

    font-size: 16px;
    font-weight: 700;
    color: var(--text);
   

    border: 2px solid var(--block);

    transition: border-color .15s ease, transform .15s ease, background-color .15s ease;

    &.regular {
        font-weight: 400;
    }

    &.tg {
        background-color: #43B4CD;
        border-color: #43B4CD;

        &:hover {
            border-color: #4ECBE7;
        }
    }

    &.blue {
        border-color: var(--accent);
        background-color: var(--accent);

        &.half {
            opacity: .7;

            &:hover {
                transform: scale(1);
            }
        }

        &:hover {
            border-color: var(--accent);

            transform: scale(1.02);
        }
    }

    &:hover {
        border-color: var(--textSecond);
    }

    .btnText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .absIcon {
        position: absolute;
        top: 50%;
        right: 10px;

        transform: translateY(-50%);

        color: #475774;
    }

    .mainImg {
        margin-right: 10px;

        width: 22px;

        color: var(--accent);
    }
}

.field {
    width: 100%;

    @media (max-width: 500px) {
        .label {
            font-size: 16px !important;
        }

        input, textarea {
            height: 55px !important;
            font-size: 22px !important;
        }
    }

    .quick {
        display: grid;

        grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
        grid-gap: 15px;

        padding-top: 5px;

        @media (max-width: 600px) {

            .mainBtn {
                height: 45px !important;
                font-size: 18px !important;
            }
        }

        @media (max-width: 500px) {
            grid-template-columns: minmax(0,1fr) minmax(0,1fr) ;
            padding: 0 10px !important;
        }

        .mainBtn {
            height: 50px;

            font-size: 22px;
        }
    }

    .label {
        font-size: 18px;
        padding: 0 20px;
        margin-bottom: 10px;
        font-weight: 500;

        color: var(--textSecond);
    }

    input, textarea {
        color: var(--text);
        background-color: var(--back);
        height: 60px;
        width: 100%;
        border-radius: 20px;
        border: 3px solid var(--block);
        padding: 0 20px;
        font-size: 1.1rem;
        transition: border-color 150ms ease;
        font-family: "Inter" !important;

        box-shadow: 0px 5px 6px 0px rgba(17, 20, 27, 0.12);

        &:hover,
        &:focus {
            border-color: var(--accent) !important;
        }


        &::placeholder {
            opacity: 1;
            /* Firefox */
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-background-clip: text;
            -webkit-text-fill-color: var(--accent);
            transition: background-color 5000s ease-in-out 0s;
            box-shadow: inset 0 0 20px 20px var(--accent);

            transition: all .15s ease;
        }
    }

    .input {
        position: relative;

        .absPart {
            position: absolute;
            left: 15px;
            top: 50%;

            transform: translateY(-50%);

            font-size: 28px;
            color: var(--accent);
            font-weight: 700;

            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                color: var(--accent);
                width: 27px;
                height: 27px;
            }

            img {
                width: 30px;
                height: 30px;

                border-radius: 50%;
            }
        }    

        &:hover,
        &:focus {
            input {
                border-color: var(--accent) !important;
            }
        }

        .action {
            cursor: pointer;
            position: absolute;
            top: 50%;
            right: 0px;
            transform: translate(-50%, -50%);
            height: 25px;
            width: 25px;

            svg {
                height: 100%;
                width: 100%;
                opacity: 1;
                color: var(--textSecond);
            }
        }
    }
}

.dropdown {
    cursor: default;
    position: absolute;
    width: 215px;
    padding: 15px 10px 15px 10px;

    max-height: 400px;

    display: flex;
    flex-direction: column;

    top: calc(100% + 25px);
    right: 0;

    transform: translate(0, 0);

    border-radius: 25px;

    animation: selectAppear 300ms ease both;
    background-color: var(--block);
    backdrop-filter: blur(7px);

    z-index: 99;

    box-shadow: 0px 1px 10px 0px rgba(17, 20, 27, 0.5);

    &.centered {
        transform: translate(50%) !important;
        right: 50%;

        animation: centerAppear 300ms ease both;

        @keyframes centerAppear {
            0% {
                transform: translate(-30px, -50%);
                opacity: 0;
            }

            100% {
                transform: translateY(0px, -50%);
                opacity: 1;
            }
        }
    }

    &.disappear {
        animation: fadeOut;
        animation-duration: 200ms;
        animation-timing-function: ease-out;
    }

    &.grid {
        padding: 5px 5px;
    }

    .displayBlock {
        position: relative;

       display: flex;
       align-items: center;   
       justify-content: space-between;   
       
       padding: 20px 25px;

       &::after {
            content: "";
            position: absolute;

            left: 0;
            top: 0;

            width: 100%;
            height: 1px;
            background: #263144;
        }

        h6 {
            font-size: 16px;
            font-weight: 700;
        }
    }

    .list {
        overflow-y: auto;
        overflow-x: hidden;

        display: grid;
        grid-template-rows: repeat(auto-fit, 45px);
        grid-gap: 10px;

        h5 {
            font-size: 14px;
            font-weight: 700;

            padding: 0 15px 15px 15px;
        }

        .listBlock {
            position: relative;

            &:last-child {
                padding: 0;
                margin: 0;
            }
        }

        .loadBox {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: 199;
            backdrop-filter: blur(7px);

            border-radius: 30px;
        }


        &::-webkit-scrollbar-track {
            margin: 25px 0;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -8px;
        left: calc(50% - 16px);
        transform: translateX(-50%);

        width: 25px;
        height: 25px;

        background-color: var(--block);

        border-radius: 5px;

        z-index: -1;

        transform: rotate(45deg) translateZ(-1px);
    }

    .head {
        position: relative;
        font-size: 18px;
        font-weight: 300;
        padding: 0 15px 15px 15px;

        &:after {
            content: "";

            position: absolute;
            bottom: 0;
            left: 50%;

            transform: translateX(-50%);

            width: calc(100% - 30px);
            height: 1px;

            background-color: #273140;
        }
    }
}

.mainbase {
    .title {
        display: flex;
        align-items: flex-end;
        margin-top: 50px;

        margin-bottom: 30px;

        &.po {
            @media (max-width: 660px) {
                align-items: center !important;

                h2 {
                    font-size: 26px !important;
                }
            }
        }

        @media (max-width: 500px) {
            margin-top: 30px;

            h2 {
                font-size: 38px !important;
            }

            h5 {
                font-size: 18px !important;
            }

            .count {
                width: 45px !important;
                height: 45px !important;

                font-size: 20px !important;

                margin-right: 10px !important;
            }

            svg {
                width: 35px !important;
                height: 35px !important;
            }
        }

        .count {
            width: 50px;
            height: 50px;

            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 22px;
            font-weight: 700;
            color: var(--accent);

            background-color: var(--block);

            border-radius: 50%;

            margin-right: 15px;
        }

        .titleImg {
            width: 40px;
            height: 40px;

            margin-right: 15px;

            color: var(--accent);
        }

        h2 {
            font-weight: 800;
            font-size: 48px;

            line-height: 90%;
            
        }

        h5 {
            font-size: 22px;
            font-weight: 500;

            color: var(--textSecond);

            margin-left: 10px;
        }
    }
}

.mainStatus {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 500px) {
        .statusHead {
            width: 70px !important;
            height: 70px !important;

            svg {
                width: 55px !important;
                height: 55px !important;
            }
        }

        h3 {
            font-size: 24px !important;
        }

        h4 {
            max-width: 350px !important;
            font-size: 14px !important;
            line-height: 135% !important;
        }
    }

    .statusHead {
        width: 100px;
        height: 100px;

        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        background: var(--block);

        margin-bottom: 20px;

        svg {
            color: var(--accent);
            width: 70px;
            height: 70px;

            
            &.load {
                animation: rotate 2s linear infinite;
            }
        }
    }

    h3 {
        font-weight: 700;
        font-size: 28px;
        margin-bottom: 10px;

        text-align: center;
    }

    h4 {
        width: 100%;
        max-width: 550px;
        text-align: center;
        line-height: 170%;

        color: var(--textSecond);
    }
}

.custom-toast {
    background-color: var(--block);
}

.blockLoader {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    left: 0;
    top: 0;

    svg {
        color: var(--accent);
        width: 50px;
        height: 50px;

        animation: rotate 2s linear infinite;
    }
}

.switcher {
    cursor: pointer;
    width: 55px;
    height: 23px;

    border-radius: 23px;

    background-color: var(--block);
    border: 2px solid var(--block);

    position: relative;

    &.on {
        .inside {
            box-shadow: 0px 5px 6px 0px rgba(17, 20, 27, 0.12);
            opacity: 1;

            left: calc(100% + 2px);
            transform: translateY(-50%) translateX(-100%);
        }
    }

    .inside {
        width: 23px;
        height: 23px;

        border-radius: 50%;
        background: var(--accent);

        position: absolute;
        top: 50%;
        left: -2px;
        transform: translateY(-50%);

        transition: opacity .3s ease, box-shadow .5s ease, left .3s ease, transform .3s ease;

        opacity: 0.5;
    }
}